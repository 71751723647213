<template>
  <div>
    <b-card no-body class="p-3 d-flex" style="gap: 10px 0">
      <h2 class="mb-2">{{ training.tr_heading }}</h2>

      <div
        class="w-100 border rounded-lg p-2"
        v-html="training.tr_description"
      />

      <b-card class="border">
        <b-row
          style="gap: 20px 0"
          v-if="training.tr_material && training.tr_material.length > 0"
        >
          <b-col
            v-for="(m, index) in training.tr_material"
            :key="'m_' + index"
            cols="6"
            class="py-2 d-flex flex-column justify-content-center align-items-center"
          >
            <h3>{{ m.name }}</h3>
            <div v-if="m.uploaded_file">
              <embed
                :src="$FILESURL + m.uploaded_file"
                width="100%"
                height="200px"
              />
              <br />
              <span>
                <a target="_blank" :href="$FILESURL + m.uploaded_filek">open</a>
              </span>
            </div>

            <div v-if="m.file_link">
              <embed :src="m.file_link" width="100%" height="200px" />
              <br />
              <span>
                <a target="_blank" :href="m.file_link">open</a>
              </span>
            </div>

            <div v-if="m.youtube_link">
              <div v-html="m.youtube_link"></div>
            </div>
          </b-col>
        </b-row>
        <div v-else>No Content Available.</div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCol, BRow, BButton, BBadge } from "bootstrap-vue";
import CommonServices from "../../../apiServices/CommonServices";
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BBadge,
    FeatherIcon,
  },
  data() {
    return {
      tr_id: null,
      training: [],
    };
  },
  methods: {
    async getSingleTraining() {
      try {
        const res = await CommonServices.getSingleTraining(this.tr_id);
        this.training = res.data.data;
      } catch (error) {
        console.log({ error });
      }
    },
  },
  beforeMount() {
    const { tr_id } = this.$route.params;
    if (!isNaN(tr_id)) {
      this.tr_id = tr_id;
      this.getSingleTraining();
    } else {
      console.log("Training ID is not a number ", tr_id);
    }
  },
};
</script>